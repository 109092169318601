import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import {
    FaTwitterSquare,
    FaGithubSquare,
    FaDev,
    FaRssSquare,
} from 'react-icons/fa';

import { rhythm, scale } from '../utils/typography';

const SocialLink = ({ url, label, children }) => (
    <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={label}
        title={label}
        style={{
            ...scale(0.7),
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
            paddingLeft: '5px',
            paddingRight: '5px',
        }}
    >
        {children}
    </a>
);

const Bio = ({ lang }) => {
    const data = useStaticQuery(graphql`
        query BioQuery {
            avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
                childImageSharp {
                    fixed(width: 50, height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            site {
                siteMetadata {
                    author
                    siteUrl
                    social {
                        twitter
                        github
                        devto
                    }
                }
            }
        }
    `);

    const { author, social, siteUrl } = data.site.siteMetadata;
    return (
        <div>
            <div
                style={{
                    display: `flex`,
                }}
            >
                <Image
                    fixed={data.avatar.childImageSharp.fixed}
                    alt={author}
                    style={{
                        marginRight: rhythm(1 / 2),
                        marginBottom: 0,
                        minWidth: 50,
                        borderRadius: `100%`,
                    }}
                    imgStyle={{
                        borderRadius: `50%`,
                    }}
                />
                <small>
                    {lang === 'de' ? (
                        <Fragment>
                            Hi, ich bin Nicolas Hollmann und das ist meine Seite
                            und Blog.
                            <br />
                            Viel Spaß beim Lesen! 📖
                            <br />
                            <br />
                        </Fragment>
                    ) : (
                        <Fragment>
                            Hi, I'm Nicolas Hollmann and this is my personal
                            site and blog.
                            <br />
                            Happy reading! 📖
                            <br />
                            <br />
                        </Fragment>
                    )}
                </small>
            </div>
            <div
                style={{
                    display: `flex`,
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <SocialLink
                    url={`https://twitter.com/${social.twitter}`}
                    label="Twitter"
                >
                    <FaTwitterSquare />
                </SocialLink>
                <SocialLink
                    url={`https://github.com/${social.github}`}
                    label="GitHub"
                >
                    <FaGithubSquare />
                </SocialLink>
                <SocialLink
                    url={`https://dev.to/${social.devto}`}
                    label="Dev.to"
                >
                    <FaDev />
                </SocialLink>
                <SocialLink url={`${siteUrl}/rss.xml`} label="RSS">
                    <FaRssSquare />
                </SocialLink>
            </div>
        </div>
    );
};

export default Bio;
