import React from 'react';
import { Link } from 'gatsby';

import Bio from './bio';
import { TopHeading } from './heading';

const EnNav = () => (
    <nav>
        <Link to={`/`}>Home</Link>
        <br />
        <Link to={`/blog`}>Blog</Link>
        <br />
        <Link to={`/tags`}>Tags</Link>
        <br />
        <Link to={`/aboutme`}>About me</Link>
        <br />
        <br />
        <Link to={`/legal`}>Legal Notice</Link>
        <br />
        <Link to={`/privacy`}>Privacy Policy</Link>
        <br />
    </nav>
);

const DeNav = () => (
    <nav>
        <Link to={`/de`}>Startseite</Link>
        <br />
        <Link to={`/de/blog`}>Blog</Link>
        <br />
        <Link to={`/de/tags`}>Tags</Link>
        <br />
        <Link to={`/de/aboutme`}>Über mich</Link>
        <br />
        <br />
        <Link to={`/de/legal`}>Impressum</Link>
        <br />
        <Link to={`/de/privacy`}>Datenschutzerklärung</Link>
        <br />
    </nav>
);

class Layout extends React.Component {
    onGermanClick(e) {
        if (!this.props.hasGerman) {
            if (
                !window.confirm(
                    'There is no german translation for this article. ' +
                        'You will be redirected to the homepage. Do you want to proceed?'
                )
            ) {
                e.preventDefault();
            }
        }
    }

    render() {
        const { title, children, lang, slug, hasGerman } = this.props;

        const englishLink =
            slug === undefined ? '/' : lang === 'en' ? slug : slug.substring(3);
        const germanLink =
            slug === undefined || !hasGerman
                ? '/de/'
                : lang === 'de'
                ? slug
                : '/de' + slug;

        return (
            <div className="wrapper">
                <div className="headerWrapper">
                    <header>
                        <TopHeading smallBottom>
                            <Link
                                style={{
                                    boxShadow: `none`,
                                    textDecoration: `none`,
                                    color: `inherit`,
                                }}
                                to={`/`}
                            >
                                {title}
                            </Link>
                        </TopHeading>
                        <Link to={englishLink}>English</Link> |{' '}
                        <Link
                            style={{ color: hasGerman ? '#007acc' : '#b32400' }}
                            to={germanLink}
                            onClick={e => {
                                this.onGermanClick(e);
                            }}
                        >
                            German
                        </Link>
                        <br />
                        <br />
                        <Bio lang={lang} />
                        {lang === 'de' ? <DeNav /> : <EnNav />}
                    </header>
                </div>
                <div className="content">
                    <main>{children}</main>
                    <footer>
                        &copy; {new Date().getFullYear()}, Nicolas Hollmann
                    </footer>
                </div>
            </div>
        );
    }
}

export default Layout;
