import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, image, slug, title }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        author
                        description
                        siteUrl
                        social {
                            twitter
                        }
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const metaImage = image ? `${site.siteMetadata.siteUrl}/${image}` : null;
    const url = `${site.siteMetadata.siteUrl}${slug}`;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            {...(title
                ? {
                      titleTemplate: `%s | ${site.siteMetadata.title}`,
                      title,
                  }
                : {
                      title: `Nicolas Hollmann`,
                  })}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: 'og:url',
                    content: url,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:site_name`,
                    content: site.siteMetadata.title,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: `@${site.siteMetadata.social.twitter}`,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ]
                .concat(
                    metaImage
                        ? [
                              {
                                  property: 'og:image',
                                  content: metaImage,
                              },
                              {
                                  name: 'twitter:image',
                                  content: metaImage,
                              },
                          ]
                        : []
                )
                .concat(meta)}
        />
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    slug: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    image: PropTypes.string,
    title: PropTypes.string,
};

export default SEO;
