import React from 'react';
import { rhythm, scale } from '../utils/typography';

export const TopHeading = ({ children, smallBottom }) => (
    <h1
        style={{
            ...scale(1.2),
            marginBottom: smallBottom ? rhythm(0.5) : rhythm(1.5),
            marginTop: 0,
        }}
    >
        {children}
    </h1>
);
